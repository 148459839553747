// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-buy-essay-online-html-jsx": () => import("./../../../src/pages/buy-essay-online.html.jsx" /* webpackChunkName: "component---src-pages-buy-essay-online-html-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-research-paper-writing-service-html-jsx": () => import("./../../../src/pages/research-paper-writing-service.html.jsx" /* webpackChunkName: "component---src-pages-research-paper-writing-service-html-jsx" */),
  "component---src-pages-write-my-essay-html-jsx": () => import("./../../../src/pages/write-my-essay.html.jsx" /* webpackChunkName: "component---src-pages-write-my-essay-html-jsx" */)
}

